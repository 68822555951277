import { useStores } from "@/stores";
import { AddedProductPopupAnimation, CartPopupAnimation, ClientOnly, Link, Money, useAddedProduct, useI18n, } from "@jog/react-components";
import classNames from "classnames";
import { observer } from "mobx-react";
import { useMemo } from "react";
import { LineItem } from "./LineItem";
export const AddedProductPopup = observer(({ className }) => {
    const { skus } = useAddedProduct();
    const { cartStore } = useStores();
    const lineItems = useMemo(() => { var _a; return ((_a = cartStore.cartLineItems) === null || _a === void 0 ? void 0 : _a.filter((item) => skus.includes(item.sku))) || []; }, [cartStore.cartLineItems, skus]);
    const { $t } = useI18n();
    return (<AddedProductPopupAnimation className={className}>
            <div className={classNames("w-[280px] border-gray-main bg-white py-4 px-3 shadow", {
            hidden: lineItems.length === 0,
        })}>
                <h3 className="text-sm font-medium">{$t("Added to cart")}</h3>
                <ul className="no-scrollbar mb-6 max-h-[440px] overflow-y-auto">
                    {lineItems === null || lineItems === void 0 ? void 0 : lineItems.map((item) => <LineItem className="py-6" key={item.id} item={item} added/>)}
                </ul>
                <Link href="/cart" className="flex w-full justify-center bg-black py-3 text-sm font-normal text-gray-cream hover:underline">
                    {$t("To cart")}
                </Link>
            </div>
        </AddedProductPopupAnimation>);
});
export const CartPopup = observer(({ show, className }) => {
    const { cartStore } = useStores();
    const lineItems = useMemo(() => cartStore.cartLineItems || [], [cartStore.cartLineItems]);
    const { $t } = useI18n();
    return (<CartPopupAnimation show={show} className={className}>
            <ClientOnly>
                {cartStore.cartTotalItems ? (<div className="w-[336px] bg-white p-5">
                        <h2 className="mb-2 text-[19px] font-medium leading-tight text-black">
                            {`${$t("Cart")} (${cartStore.cartLineItemsCount})`}
                        </h2>
                        <ul className="no-scrollbar max-h-[360px] overflow-y-auto">
                            {lineItems.map((item) => (<LineItem className="py-3" key={item.id} item={item}/>))}
                        </ul>
                        <div className="mt-4 border-t border-t-grey-light pt-3">
                            {cartStore.getCartPrices.originSubTotal && (<div className="flex justify-between text-xs leading-tight text-black">
                                    <p>{$t("Subtotal")}</p>
                                    <p>
                                        <Money centAmount={cartStore.getCartPrices.originSubTotal}/>
                                    </p>
                                </div>)}
                            {cartStore.getCartPrices.totalDiscount > 0 && (<div className="mt-2 flex justify-between text-xs leading-tight text-black">
                                    <p>{$t("Discount")}</p>
                                    <p className="text-red-sale">
                                        -<Money centAmount={cartStore.getCartPrices.totalDiscount}/>
                                    </p>
                                </div>)}
                            <div className="mt-2">
                                <div className="flex justify-between text-xs leading-tight text-black">
                                    <p>{$t("Shipping costs")}</p>
                                    <p>
                                        {cartStore.getCartPrices.shippingCosts ? (<Money centAmount={cartStore.getCartPrices.shippingCosts}/>) : ($t("free"))}
                                    </p>
                                </div>
                            </div>
                            <div className="mt-3 border-t border-t-grey-light pt-3">
                                <div className="flex justify-between leading-tight text-black">
                                    <p className="align-bottom">
                                        <span className="text-sm">{$t("Total")}</span>
                                        <span className="ml-1 text-[10px]">{$t("(inc. VAT)")}</span>
                                    </p>
                                    <p className="text-sm font-medium">
                                        {!!cartStore.getCartPrices.subTotalWithoutPaymentCosts && (<Money centAmount={cartStore.getCartPrices.subTotalWithoutPaymentCosts}/>)}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="mt-6">
                            <Link href="/cart" className="flex w-full items-center justify-center bg-black py-3 px-4 text-sm uppercase text-white hover:underline disabled:bg-grey-medium">
                                {$t("To cart")}
                            </Link>
                        </div>
                    </div>) : (<div className="w-[480px] bg-white p-5 lg:py-7.5 lg:px-11.5">
                        <h2 className="text-xl capitalize text-black">{$t("YOUR SHOPPING BASKET IS STILL EMPTY")}</h2>
                        <p className="text-sm">{$t("Go shopping! :-)")}</p>
                    </div>)}
            </ClientOnly>
        </CartPopupAnimation>);
});
