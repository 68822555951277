import { usePrefetch } from "@jog/react-components";
import { fetchS3Pdp, prismicClient, retry, transPdp } from "@jog/shared";
export function prefetchSimplePdp(prefetch) {
    return prefetch("pdp", async (pageContext) => {
        const { desktopSortingByName, mobileSortingByName, faslet } = await retry(() => (pageContext === null || pageContext === void 0 ? void 0 : pageContext.previewData)
            ? prismicClient({ previewData: pageContext.previewData }).getSingle("pdp")
            : fetchS3Pdp()).then((res) => transPdp(res.data));
        return { desktopSortingByName, mobileSortingByName, faslet };
    });
}
export const useSimplePdp = () => {
    const { data } = usePrefetch("pdp");
    return data;
};
