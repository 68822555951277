export const localStorage = {
    setItem: (key, value) => {
        if (typeof window !== "undefined") {
            window.localStorage.setItem(key, JSON.stringify(value));
        }
    },
    getItem: (key, defaultV) => {
        if (typeof window !== "undefined") {
            const value = window.localStorage.getItem(key);
            if (value !== undefined && value !== "undefined") {
                try {
                    return (value && JSON.parse(value));
                }
                catch {
                    return defaultV || value;
                }
            }
        }
    },
    removeItem: (key) => {
        if (typeof window !== "undefined") {
            window.localStorage.removeItem(key);
        }
    },
};
export const checkoutTransactionEventSent = (orderNumber) => {
    return {
        exist() {
            return localStorage.getItem(`${orderNumber}-checkoutTransactionEventSent`) === "1";
        },
        mark() {
            localStorage.setItem(`${orderNumber}-checkoutTransactionEventSent`, "1");
        },
    };
};
export const sessionController = () => {
    return {
        get: async (getSession) => {
            return localStorage.getItem("cognito-auth") || (await getSession().then((res) => res === null || res === void 0 ? void 0 : res.accessToken));
        },
        set: (session) => {
            localStorage.setItem("cognito-auth", session.accessToken);
        },
        clear: () => {
            localStorage.removeItem("cognito-auth");
        },
    };
};
export const setTwProfilekey = (key) => localStorage.setItem("x-tw-profilekey", { key });
export const getTwProfilekey = () => { var _a; return (_a = localStorage.getItem("x-tw-profilekey", { key: "" })) === null || _a === void 0 ? void 0 : _a.key; };
