import { gtmPush } from "./index";
export const gtmNewsletterSubscription = () => {
    gtmPush({ event: "newsletter_subscription", formLocation: "widget_homepage" });
};
export const gtmPageTracking = (gtmTypeOfPage) => {
    gtmPush({ ecomm_pagetype: gtmTypeOfPage || "other" });
};
export const gtmAddUserId = (userId) => {
    if (userId)
        gtmPush({ "user-id": userId });
};
export const gtmEnhancedConversion = (paymentOrderInfo) => {
    var _a;
    const email = (_a = paymentOrderInfo === null || paymentOrderInfo === void 0 ? void 0 : paymentOrderInfo.billingAddress) === null || _a === void 0 ? void 0 : _a.email;
    if (!email)
        return;
    gtmPush({ email, event: "fireEnhancedConversionTag" });
};
