import { getMyCart, gtmCartPageBasketOverview, localStorage, sessionController } from "@jog/shared";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import { createContext, useCallback, useEffect, useState } from "react";
import { usePending } from "../components";
export const useCart = ({ cartStore }) => {
    const { setPending } = usePending();
    useEffect(() => {
        var _a, _b;
        if (cartStore.isCartViewLoaded)
            gtmCartPageBasketOverview(((_b = (_a = cartStore.cartView) === null || _a === void 0 ? void 0 : _a.body) === null || _b === void 0 ? void 0 : _b.lineItems) || []);
    }, [cartStore.cartView, cartStore.isCartViewLoaded]);
    const cleanCosts = useCallback(async () => {
        var _a, _b, _c;
        await cartStore.cleanCosts();
        if ((_c = (_b = (_a = cartStore.cartView) === null || _a === void 0 ? void 0 : _a.body) === null || _b === void 0 ? void 0 : _b.cartMessage) === null || _c === void 0 ? void 0 : _c.length)
            await cartStore.validateCart();
    }, [cartStore]);
    useEffect(() => {
        setPending(true);
        cleanCosts().finally(() => {
            setPending(false);
        });
    }, [cleanCosts, setPending]);
};
export const CartContext = createContext({
    cart: null,
    isLoading: false,
});
export const CartProvider = ({ children, cartStore }) => {
    var _a;
    const [cart, _setCart] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const setCart = useCallback((cart) => {
        _setCart(cart);
        localStorage.setItem("cart", cart);
    }, []);
    useEffect(() => {
        if (cartStore.cartView) {
            setCart(cartStore.cartView.body);
        }
    }, [cartStore.cartView, setCart]);
    const router = useRouter();
    const session = useSession();
    useEffect(() => {
        var _a;
        if ((_a = session.data) === null || _a === void 0 ? void 0 : _a.user) {
            sessionController().set(session.data);
        }
    }, [session.data]);
    useEffect(() => {
        var _a;
        if (router.isReady && !isLoading) {
            if (!((_a = session.data) === null || _a === void 0 ? void 0 : _a.user))
                return;
            if (!cart) {
                setIsLoading(true);
                getMyCart()
                    .then(async (cart) => {
                    if (cart.body) {
                        setCart(cart.body);
                        cartStore.syncCart(cart.body);
                    }
                    const newCart = await cartStore.createCart(true);
                    if (newCart) {
                        _setCart(newCart);
                    }
                })
                    .finally(() => {
                    setIsLoading(false);
                });
            }
        }
    }, [cart, cartStore, router.isReady, (_a = session.data) === null || _a === void 0 ? void 0 : _a.user, isLoading, setCart]);
    return <CartContext.Provider value={{ cart, isLoading }}>{children}</CartContext.Provider>;
};
