import * as prismic from "@prismicio/client";
import * as prismicNext from "@prismicio/next";
import { GraphQLClient } from "graphql-request";
import { lowerCaseLocale } from "../helper";
const ENDPOINT = process.env.NEXT_PUBLIC_PRISMIC_ENDPOINT || process.env.PRISMIC_END_POINT || "";
const TOKEN = process.env.NEXT_PUBLIC_PRISMIC_TOKEN || process.env.PRISMIC_TOKEN || "";
let client = null;
let graphQLClient = null;
export const prismicClient = (options) => {
    const repositoryName = prismic.getRepositoryName(ENDPOINT);
    if (client) {
        if (options)
            prismicNext.enableAutoPreviews({ client, req: options.req, previewData: options.previewData });
        return client;
    }
    const lang = lowerCaseLocale();
    client = prismic.createClient(prismic.getRepositoryEndpoint(repositoryName), {
        accessToken: TOKEN,
        defaultParams: lang ? { lang } : {},
    });
    if (options)
        prismicNext.enableAutoPreviews({ client, req: options.req, previewData: options.previewData });
    return client;
};
export const prismicGQLClient = (options) => {
    const repositoryName = prismic.getRepositoryName(ENDPOINT);
    graphQLClient = new GraphQLClient(prismic.getGraphQLEndpoint(repositoryName), {
        method: "GET",
        fetch: prismicClient(options).graphQLFetch,
        jsonSerializer: JSON,
    });
    return graphQLClient;
};
