import { kebabCase } from "lodash-es";
import { useMemo } from "react";
export const useLineItemLocalized = (data) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const locale = process.env.NEXT_PUBLIC_LOCALE;
    const categoryUrl = ((_a = data.categoryAsUrlAllLocales) === null || _a === void 0 ? void 0 : _a[locale]) || ((_b = data.categoryAsUrlAllLocales) === null || _b === void 0 ? void 0 : _b.en);
    const name = ((_c = data.name) === null || _c === void 0 ? void 0 : _c[locale]) || ((_d = data.name) === null || _d === void 0 ? void 0 : _d.en);
    const slug = ((_e = data.productSlugAllLocales) === null || _e === void 0 ? void 0 : _e[locale]) || ((_f = data.productSlugAllLocales) === null || _f === void 0 ? void 0 : _f.en);
    const link = `/${categoryUrl}/${kebabCase(name)}/${kebabCase(slug)}`;
    const promotion = ((_g = data.promotionDescription) === null || _g === void 0 ? void 0 : _g[locale]) || "";
    return {
        link,
        name,
        promotion,
    };
};
export const useLineItemPrice = (data) => useMemo(() => {
    const firstPrice = data.prices.firstPriceInTheCart.centAmount;
    if (data.prices.promotionalPrice && firstPrice > data.prices.promotionalPrice.centAmount)
        return { finalPrice: data.prices.promotionalPrice.centAmount, firstPrice };
    if (data.prices.discountPrice && firstPrice > data.prices.discountPrice.centAmount)
        return { finalPrice: data.prices.discountPrice.centAmount, firstPrice };
    return { finalPrice: null, firstPrice };
}, [data.prices]);
