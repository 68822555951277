import fs from "fs";
import path from "path";
import { getDomain, typedBoolean } from "../helper";
export const prebuildRobotsTxt = async (fetch) => {
    const robotsTxtData = await fetch();
    const robotsTxt = robotsTxtData.pagesToDisallow;
    console.log(`Start write Prismic robotsTxt to file`);
    fs.writeFileSync(path.resolve() + "/src/lib/prismicio/prismic-robots-txt.json", JSON.stringify(robotsTxt));
    console.log(`Write Prismic robotsTxt success`);
    const policies = [
        {
            userAgent: "*",
            allow: "/",
            disallow: [...new Set(robotsTxtData.pagesToDisallow)],
        },
        ...robotsTxtData.otherUserAgentConfiguration,
    ];
    const policy = policies
        .map((item) => [
        item.userAgent !== undefined && `User-agent:${item.userAgent}`,
        item.allow !== undefined && `Allow: ${item.allow}`,
        item.crawlDelay !== undefined && `Crawl-delay: ${item.crawlDelay}`,
        ...(item.disallow || []).filter(typedBoolean).map((i) => `Disallow: ${i}`),
    ]
        .filter(typedBoolean)
        .join("\n"))
        .join("\n\n");
    const origin = getDomain();
    const sitemap = [`Sitemap: ${origin}/sitemap.xml`, `Host: ${origin}`].join("\n");
    const robots = [policy, sitemap].join("\n\n");
    fs.writeFileSync(path.resolve() + "/public/robots.txt", robots, "utf-8");
    console.log(`Write public robots.txt success`);
};
export const transOtherUserAgentConfiguration = (other_user_agent_configuration) => [...(other_user_agent_configuration || [])].filter(typedBoolean).map((item) => {
    const userAgentConfig = {};
    if (item.user_agent) {
        userAgentConfig.userAgent = item.user_agent;
    }
    if (item.crawl_delay) {
        userAgentConfig.crawlDelay = item.crawl_delay;
    }
    if (item.disallow_list) {
        userAgentConfig.disallow = item.disallow_list.split(",").map((str) => str.replace(" ", ""));
    }
    return userAgentConfig;
});
export const transPagesToDisallow = (pages_to_disallow) => [...(pages_to_disallow || [])]
    .filter(typedBoolean)
    .map((i) => i.path_wildcard)
    .filter(typedBoolean);
