import { checkUser, gtmNewsletterSubscription, localStorage, recaptchaValidateToken, sessionController, } from "@jog/shared";
import classNames from "classnames";
import dayjs from "dayjs";
import { ErrorMessage } from "formik";
import { getSession, signIn } from "next-auth/react";
import { useRouter } from "next/router";
import { useCallback } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { usePending } from "../components";
import { useI18n } from "./useI18n";
import { useValidateEmail } from "./useValidateEmail";
export const useRecaptcha = () => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    return useCallback(async (action) => {
        if (!executeRecaptcha) {
            console.log("Execute recaptcha not yet available");
            return;
        }
        return await executeRecaptcha(action);
    }, [executeRecaptcha]);
};
export const useNewsletter = () => {
    const { validateEmail } = useValidateEmail();
    const getReCaptchaToken = useRecaptcha();
    const { $t } = useI18n();
    const { setPending } = usePending();
    return {
        validateOnBlur: true,
        validateOnChange: false,
        validate: useCallback((values) => {
            if (!validateEmail(values.email)) {
                console.log("1");
                return { email: $t("Invalid email address") };
            }
            return;
        }, [$t, validateEmail]),
        onSubmit: useCallback(async (values, { setErrors }) => {
            const token = await getReCaptchaToken("validateEmail");
            if (!token) {
                setErrors({ email: "Recaptcha not yet available" });
                return;
            }
            const lowerCaseEmail = values.email.toLowerCase();
            setPending(true);
            const successful = await recaptchaValidateToken(token);
            if (!successful) {
                setErrors({ email: "Captcha validation was not successful" });
                return;
            }
            localStorage.setItem("newsletter", { isSubscribed: true, date: dayjs().toString() });
            gtmNewsletterSubscription();
            window === null || window === void 0 ? void 0 : window.location.replace(`${values.newsletter}?email=${encodeURIComponent(lowerCaseEmail)}&bron=${values.source}`);
        }, [getReCaptchaToken, setPending]),
    };
};
export const useCheckEmail = (setEmail, checkCountry) => {
    const { validateEmail } = useValidateEmail();
    const getReCaptchaToken = useRecaptcha();
    const router = useRouter();
    const { $t } = useI18n();
    return {
        validateOnBlur: true,
        validateOnChange: false,
        validate: useCallback((values) => {
            const valid = validateEmail(values.email);
            if (!valid) {
                return { email: $t("Invalid email address") };
            }
            return;
        }, [$t, validateEmail]),
        onSubmit: useCallback(async (values, { setErrors }) => {
            const token = await getReCaptchaToken("validateEmail");
            if (!token) {
                setErrors({ email: "Recaptcha not yet available" });
                return;
            }
            const lowerCaseEmail = values.email.toLowerCase();
            const { available, message, country } = await checkUser(lowerCaseEmail, token);
            if (message) {
                setErrors({ email: message || "Captcha validation was not successful" });
                return;
            }
            if (available) {
                await router.push(`/signup?email=${encodeURIComponent(lowerCaseEmail)}`);
                return;
            }
            if (checkCountry && !checkCountry(country)) {
                setErrors({ country });
                return;
            }
            setEmail(lowerCaseEmail);
        }, [checkCountry, getReCaptchaToken, router, setEmail]),
    };
};
export const useLogin = (onLogin) => {
    const { $t } = useI18n();
    return {
        validateOnBlur: true,
        validateOnChange: false,
        validate: useCallback((values) => {
            if (values.password.length <= 4) {
                return { password: $t("Invalid password") };
            }
        }, [$t]),
        onSubmit: useCallback(async (values, { setErrors }) => {
            const res = await signIn("credentials", {
                redirect: false,
                email: values.email.toLowerCase(),
                password: values.password,
            }).then(async (res) => {
                if (res === null || res === void 0 ? void 0 : res.ok) {
                    const session = await getSession();
                    if (session)
                        sessionController().set(session);
                }
                return res;
            });
            if (res === null || res === void 0 ? void 0 : res.ok) {
                await (onLogin === null || onLogin === void 0 ? void 0 : onLogin());
            }
            if (res === null || res === void 0 ? void 0 : res.error) {
                setErrors({ password: $t("Invalid password") });
            }
        }, [$t, onLogin]),
    };
};
export const LoginFormField = ({ children }) => (<div className="grid w-full grid-cols-12 gap-y-1.25">{children}</div>);
export const LoginFormFieldLabel = ({ children, name }) => (<div className="col-span-12 flex items-center lg:col-span-3">
        <label htmlFor={name}>{children} *</label>
    </div>);
export const LoginFormFieldError = ({ name }) => (<ErrorMessage render={(msg) => <div className="col-span-12 mt-1.25 text-right text-13 text-red-main">{msg}</div>} name={name}/>);
export const LoginFormFieldInput = ({ children, className }) => (<div className={classNames("col-span-12 lg:col-span-9", className)}>{children}</div>);
export const LoginFormButton = ({ children, disabled, className, }) => (<div className="flex w-full justify-start lg:justify-end">
        <button type="submit" className={className} disabled={disabled}>
            {children}
        </button>
    </div>);
