import { addUserAddress, checkUser, createUser, getMyCart, getUserAddresses, getUserCountry, getUserData, updateUserAddress, } from "@jog/shared";
import { makeAutoObservable } from "mobx";
const emptyAddress = {
    city: "",
    country: "",
    familyName: "",
    gender: "",
    houseNumber: "",
    name: "",
    postalCode: "",
    streetName: "",
    uid: "",
};
export class UserStore {
    constructor() {
        this.isLoading = false;
        this.unregisteredEmail = "";
        this.userData = null;
        this.userAddress = null;
        this.userAddresses = [];
        this.userOtherAddresses = [];
        this.userCartId = "";
        this.userCountry = "";
        this.isRegistrationError = false;
        makeAutoObservable(this);
    }
    async getMyCart() {
        var _a;
        try {
            const userCart = await getMyCart();
            if ((_a = userCart === null || userCart === void 0 ? void 0 : userCart.body) === null || _a === void 0 ? void 0 : _a.id) {
                this.userCartId = userCart.body.id;
            }
        }
        catch (e) {
            console.log(e);
        }
    }
    /* Get the user data and store it in userData observable */
    async getUserData(updateCart) {
        try {
            const userData = await getUserData();
            userData.address = userData.address || emptyAddress;
            this.userData = userData;
            if (updateCart)
                await this.getMyCart();
            return userData;
        }
        catch (e) {
            console.log(e);
        }
    }
    /* Check if user email exists in the system */
    async checkIfUserExists(email, token) {
        this.isLoading = true;
        try {
            const userExist = await checkUser(email, token || "");
            this.isLoading = false;
            return userExist;
        }
        finally {
            this.isLoading = false;
        }
    }
    async createNewUser(userInfo) {
        this.isLoading = true;
        try {
            await createUser(userInfo);
            this.isRegistrationError = false;
        }
        catch (e) {
            this.isRegistrationError = true;
            console.log(`CreateUser was not successful: ${JSON.stringify(e, null, 2)}`);
            console.log(e);
        }
        this.isLoading = false;
    }
    async getUserAddresses() {
        try {
            this.userAddresses = await getUserAddresses().then((res) => res.body);
            this.userAddress = this.userAddresses.find((address) => address.defaultAddress) || this.userAddresses[0];
            this.userOtherAddresses = this.userAddresses.filter((address) => { var _a; return ((_a = this.userAddress) === null || _a === void 0 ? void 0 : _a.uid) !== address.uid; });
        }
        catch (e) {
            console.log(e);
        }
    }
    async accountAddUserAddress(userAddress) {
        this.isLoading = true;
        try {
            await addUserAddress(userAddress);
            await this.getUserData(false);
            await this.getUserAddresses();
        }
        catch (error) {
            console.log(error);
        }
        this.isLoading = false;
    }
    async accountUpdateUserAddress(userAddress, addressUid, isCheckout = false) {
        try {
            await updateUserAddress(userAddress, addressUid);
            await this.getUserData(false);
            if (!isCheckout)
                await this.getUserAddresses();
        }
        catch (e) {
            console.log(e);
        }
    }
    clearRegistrationError() {
        this.isRegistrationError = false;
    }
    async getCountry() {
        try {
            this.userCountry = await getUserCountry().then((r) => { var _a; return ((_a = (r || "")) === null || _a === void 0 ? void 0 : _a.replace(/\s/g, "")) || ""; });
            return this.userCountry;
        }
        catch (error) {
            console.log(error);
        }
    }
}
