import { BuckarooSdk } from "@jog/shared";
import { createContext, useCallback, useContext, useEffect, useMemo, useState, } from "react";
const UsePaymentContext = createContext({
    selectedPaymentMethod: "",
    setSelectedPaymentMethod: () => { },
    setBillingCountry: () => { },
    init: () => { },
    billingCountry: "",
    supportsApplePay: false,
});
export const PaymentProvider = ({ children, defaultPayment, defaultBillingCountry, }) => {
    const [billingCountry, setBillingCountry] = useState(defaultBillingCountry);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(defaultPayment);
    const init = useCallback(() => {
        setSelectedPaymentMethod(defaultPayment);
    }, [defaultPayment]);
    useEffect(() => {
        init();
    }, [init]);
    const [supportsApplePay, setSupportsApplePay] = useState(false);
    useEffect(() => {
        const merchantIdentifier = process.env.NEXT_PUBLIC_BUCKAROO_MERCHANT_ID;
        BuckarooSdk.ApplePay.checkApplePaySupport(merchantIdentifier).then((applePaySupported) => {
            setSupportsApplePay(applePaySupported);
        });
    }, []);
    const value = useMemo(() => ({
        selectedPaymentMethod,
        setSelectedPaymentMethod,
        init,
        billingCountry,
        setBillingCountry,
        supportsApplePay,
    }), [billingCountry, init, selectedPaymentMethod, supportsApplePay]);
    return <UsePaymentContext.Provider value={value}>{children}</UsePaymentContext.Provider>;
};
export const usePayment = () => useContext(UsePaymentContext);
