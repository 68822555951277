import { normalizePdpImages, typedBoolean } from "@jog/shared";
import { kebabCase } from "lodash-es";
import { useRouter } from "next/router";
import { useMemo, useState } from "react";
export const getColor = (item) => { var _a, _b, _c; return ((_c = (_b = (_a = item === null || item === void 0 ? void 0 : item.attributes) === null || _a === void 0 ? void 0 : _a.find((val) => val.name === "color" && val.values)) === null || _b === void 0 ? void 0 : _b.values) === null || _c === void 0 ? void 0 : _c[0]) || "default"; };
export const useTile = (data, _group) => {
    var _a, _b;
    const [activeProduct, setActiveProduct] = useState(data);
    const product = useProduct(data);
    const [fold, setFold] = useState(true);
    const listerPageTileImage = useMemo(() => {
        var _a;
        const thumbnailImageData = activeProduct.attributes.find((attribute) => attribute.name === "thumbnailimage");
        return ((_a = thumbnailImageData === null || thumbnailImageData === void 0 ? void 0 : thumbnailImageData.values) === null || _a === void 0 ? void 0 : _a[0]) || activeProduct.image;
    }, [activeProduct]);
    const hasGroup = useMemo(() => _group && _group.length > 1, [_group]);
    const group = useMemo(() => {
        if (!hasGroup)
            return [];
        const map = _group.reduce((pre, cur) => ({ ...pre, [cur.itemno]: cur }), {});
        const colorMap = { [getColor(data)]: data.itemno };
        _group.forEach((item) => {
            const color = getColor(item);
            if (!colorMap[color]) {
                colorMap[color] = item.itemno;
            }
        });
        return Array.from(new Set([...Object.values(colorMap), ..._group.map((i) => i.itemno)])).map((i) => map[i]);
    }, [_group, data, hasGroup]);
    const promotion = ((_b = (_a = data.attributes.find((val) => val.name === "discountName" && val.values)) === null || _a === void 0 ? void 0 : _a.values) === null || _b === void 0 ? void 0 : _b.at(0)) || "";
    return {
        activeProduct,
        setActiveProduct,
        listerPageTileImage,
        product,
        fold,
        toggle: () => setFold((v) => !v),
        group,
        hasGroup,
        promotion,
    };
};
export const parseExtension = (json) => {
    try {
        return JSON.parse(json);
    }
    catch {
        return { articleName: "", colorCode: "", lowestPrice: 0 };
    }
};
export const getProductPrice = (product) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const firstPrice = ((_c = (_b = (_a = product.attributes) === null || _a === void 0 ? void 0 : _a.find((val) => { var _a; return ((_a = val.name) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === "firstprice" && val.values; })) === null || _b === void 0 ? void 0 : _b.values) === null || _c === void 0 ? void 0 : _c[0]) || "0";
    const hasDiscount = (_d = product.attributes) === null || _d === void 0 ? void 0 : _d.find((val) => val.name === "discountName" && val.values);
    const { lowestPrice } = parseExtension(((_g = (_f = (_e = product.attributes) === null || _e === void 0 ? void 0 : _e.find((val) => { var _a; return ((_a = val.name) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === "extension" && val.values; })) === null || _f === void 0 ? void 0 : _f.values) === null || _g === void 0 ? void 0 : _g[0]) || "{}");
    return { firstPrice, hasDiscount, lowestPrice };
};
export const getProductUrl = (product) => {
    var _a, _b, _c, _d, _e, _f;
    const categories = (_c = (_b = (_a = product.attributes) === null || _a === void 0 ? void 0 : _a.find((val) => val.name === "categoryAsUrl" && val.values)) === null || _b === void 0 ? void 0 : _b.values) === null || _c === void 0 ? void 0 : _c[0];
    const name = product.title;
    const slug = (_f = (_e = (_d = product.attributes) === null || _d === void 0 ? void 0 : _d.find((val) => val.name === "slug" && val.values)) === null || _e === void 0 ? void 0 : _e.values) === null || _f === void 0 ? void 0 : _f[0];
    return `/${categories}/${kebabCase(name)}/${kebabCase(slug)}`;
};
export const useProduct = (product) => useMemo(() => {
    const name = product.title;
    const { firstPrice, hasDiscount, lowestPrice } = getProductPrice(product);
    const linkItem = getProductUrl(product);
    const isDiscount = (hasDiscount || firstPrice) && Number(firstPrice) > Number(product.price);
    return {
        lowestPrice,
        firstPrice,
        name,
        linkItem,
        isDiscount,
        image: product.image,
        price: product.price,
    };
}, [product]);
export const useGallery = (data, desktopSortingByName, mobileSortingByName) => {
    const router = useRouter();
    const images = useMemo(() => {
        var _a, _b;
        return (_b = (((_a = data.attributes.find((attribute) => attribute.name === "gallery")) === null || _a === void 0 ? void 0 : _a.values) || [])) === null || _b === void 0 ? void 0 : _b.filter(typedBoolean).map((item) => JSON.parse(item));
    }, [data]);
    const mobile = useMemo(() => normalizePdpImages(images, mobileSortingByName || [], []), [images, mobileSortingByName]);
    const desktop = useMemo(() => {
        var _a;
        // When there is no flatshot image available then the hover/swipe functionality is not needed
        // When there is only a flatshot image available then the hover/swipe functionality is not needed
        const _images = normalizePdpImages(images, desktopSortingByName || [], []);
        const onmodelFront = _images.find((i) => i.label.startsWith("onmodel-front"));
        const flatshotsImage = _images.find((i) => i.label.startsWith("flatshots-front"));
        const onmodelBack = _images.find((i) => i.label.startsWith("onmodel-back"));
        const main = onmodelFront || flatshotsImage;
        if ((_a = router.query.slug) === null || _a === void 0 ? void 0 : _a.includes("jeans")) {
            // jeans shows `onmodel-back`
            return [main, onmodelBack || flatshotsImage].filter(typedBoolean);
        }
        return [main, flatshotsImage].filter(typedBoolean);
    }, [desktopSortingByName, images, router.query.slug]);
    return { mobile, desktop };
};
