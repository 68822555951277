import { getUserAddresses, getUserData, getVariants, localStorage, returnOrders, sessionController } from "@jog/shared";
import { useSession } from "next-auth/react";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { signOutUser } from "../components";
const MeContext = createContext({});
export const MeProvider = ({ children }) => {
    const { status, data } = useSession();
    const [user, setUser] = useState(null);
    const update = useCallback(async () => {
        const user = await getUserData();
        setUser(user);
        return user;
    }, []);
    useEffect(() => {
        var _a, _b;
        switch (status) {
            case "unauthenticated": {
                sessionController().clear();
                break;
            }
            case "authenticated": {
                const expires = data.expires;
                localStorage.setItem("name", (_a = data === null || data === void 0 ? void 0 : data.user) === null || _a === void 0 ? void 0 : _a.name);
                localStorage.setItem("email", (_b = data === null || data === void 0 ? void 0 : data.user) === null || _b === void 0 ? void 0 : _b.email);
                if (expires && new Date(expires).getTime() < new Date().getTime()) {
                    signOutUser();
                }
                else {
                    sessionController().set(data);
                    if (!user)
                        update();
                }
            }
        }
    }, [data, status, update, user]);
    const value = useMemo(() => ({ user, update }), [user, update]);
    return <MeContext.Provider value={value}>{children}</MeContext.Provider>;
};
export const useMe = () => useContext(MeContext);
export const useOrders = (limit = 5) => {
    const [pending, setPending] = useState(true);
    const [error, setError] = useState("");
    const [orders, setOrders] = useState([]);
    const [totalPage, setTotalPage] = useState(0);
    const [page, setPage] = useState(1);
    const getRuOrders = useCallback(async () => {
        setPending(true);
        setError("");
        await returnOrders({ limit, offset: (page - 1) * limit })
            .then((res) => {
            var _a;
            const orders = ((_a = res.orders) === null || _a === void 0 ? void 0 : _a.map((order) => ({
                ruId: order.run_order_id,
                orderType: order.order_type,
                orderNumber: order.order_id,
                orderDate: order.order_date,
                orderLines: order.line_details.line.filter((line) => line.type === "PRODUCT"),
            }))) || [];
            setOrders(orders);
            setTotalPage(Math.ceil(res.total / limit));
        })
            .catch((e) => {
            setError(e.message);
        })
            .finally(() => {
            setPending(false);
        });
    }, [limit, page]);
    useEffect(() => {
        getRuOrders().catch(() => { });
    }, [getRuOrders]);
    return { pending, error, orders, totalPage, setPage, page };
};
export const useRecentOrders = () => {
    const [pending, setPending] = useState(true);
    const [error, setError] = useState("");
    const [orders, setOrders] = useState([]);
    const getRuOrders = useCallback(async () => {
        setPending(true);
        setError("");
        await returnOrders({ limit: 3 })
            .then((res) => res.orders || [])
            .then((orders) => orders.map((order) => ({
            ruId: order.run_order_id,
            orderType: order.order_type,
            orderNumber: order.order_id,
            orderDate: order.order_date,
            orderLines: order.line_details.line.filter((line) => line.type === "PRODUCT"),
        })))
            .then((orders) => setOrders(orders))
            .catch((e) => {
            setError(e.message);
        })
            .finally(() => {
            setPending(false);
        });
    }, []);
    useEffect(() => {
        getRuOrders().catch(() => { });
    }, [getRuOrders]);
    return { pending, error, orders };
};
export const useOrderEnrich = (order) => {
    const [pending, setPending] = useState(true);
    const [error, setError] = useState("");
    const [variants, setVariants] = useState({});
    useEffect(() => {
        setPending(true);
        setError("");
        getVariants(order.orderLines.map((i) => i.barcode))
            .then((res) => setVariants(res.variants))
            .catch((e) => {
            setError(e.message);
        })
            .finally(() => {
            setPending(false);
        });
    }, [order]);
    return { pending, error, variants };
};
const AddressesContext = createContext({
    addresses: null,
    getAddresses: () => Promise.resolve(),
    pending: true,
    error: "",
});
export const AddressesProvider = ({ children }) => {
    const [pending, setPending] = useState(true);
    const [error, setError] = useState("");
    const [addresses, setAddresses] = useState(null);
    const getAddresses = useCallback(async () => {
        setPending(true);
        setError("");
        await getUserAddresses()
            .then((res) => res.body.sort((a, b) => +(b.defaultAddress || 0) - +(a.defaultAddress || 0)))
            .then((addresses) => setAddresses(addresses))
            .catch((e) => {
            setError(e.message);
        })
            .finally(() => {
            setPending(false);
        });
    }, []);
    const value = useMemo(() => ({ addresses, getAddresses, error, pending }), [addresses, error, getAddresses, pending]);
    return <AddressesContext.Provider value={value}>{children}</AddressesContext.Provider>;
};
export const useAddresses = () => useContext(AddressesContext);
