import { sleep } from "./helper";
export const scrollToWithOffset = async (selector, offset = -160) => {
    if (selector === undefined)
        return;
    const element = document.querySelector(`#${selector}`);
    if (!element)
        return;
    element.scrollIntoView({ behavior: "smooth" });
    await sleep(500);
    window.scrollBy(0, offset);
};
export const handlePreSubmitValidation = async (errors) => {
    if (errors) {
        // Pass the error key name which is the same as field ID
        await scrollToWithOffset(Object.keys(errors)[0]);
    }
    else {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }
};
export const dispatchInputChange = (input, state) => {
    const emailInput = document.querySelector(input);
    if (!emailInput)
        return;
    // Email is not shown in AWS component. Show placeholder email instead
    emailInput.setAttribute("placeholder", state);
    emailInput.setAttribute("value", state);
    emailInput.dispatchEvent(new Event("change", { bubbles: true }));
    emailInput.dispatchEvent(new Event("blur", { bubbles: true }));
    emailInput.disabled = true;
};
