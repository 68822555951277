import classNames from "classnames";
import { Arrow } from "../Arrow/Arrow";
export const ArrowButtonBase = ({ defaultBgColor, preIconShow, ArrowButtonWrapperCss, }) => {
    const ArrowButtonFC = ({ children, onClickFunction, disabled, color, bgColor, margin, isCmsBanner, inCartFooter = false, buttonType, className, hiddenIconOnMobile, }) => {
        return (<button onClick={onClickFunction} disabled={disabled} type={buttonType} className={classNames("group relative inline-block cursor-pointer whitespace-nowrap border-none px-5 uppercase text-white no-underline focus:outline-none disabled:opacity-60", preIconShow ? "lg:py-2.5 lg:pr-7.5 lg:pl-10" : "lg:py-2.5 lg:px-7.5", ArrowButtonWrapperCss, className)} style={{
                margin: margin,
                color: color,
                backgroundColor: bgColor || defaultBgColor,
                pointerEvents: disabled ? "none" : "auto",
            }}>
                {preIconShow ? (<Arrow color={color || "white"} size="middle" className={classNames("button-arrow absolute top-1/2 -translate-y-1/2 duration-300", inCartFooter
                    ? "lg:left-7.5 lg:group-hover:left-8.75"
                    : "lg:left-5 lg:group-hover:left-6.25", isCmsBanner ? "left-2.5 group-hover:left-2.5" : "left-5 group-hover:left-5", { "hidden sm:block": hiddenIconOnMobile })}/>) : null}
                {children}
            </button>);
    };
    return ArrowButtonFC;
};
