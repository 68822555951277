import { useI18n } from "@jog/react-components";
import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";
import { useEffect, useState } from "react";
const RadioRoot = (props) => (<RadioGroupPrimitive.Root {...props} className="-ml-1 -mb-1 mt-2 flex flex-wrap"/>);
const RadioItem = ({ children, ...props }) => (<RadioGroupPrimitive.Item {...props} className="group relative ml-1 mb-1 flex h-9.5 min-w-[46px] items-center justify-center border border-black bg-white px-2 text-xs hover:bg-black hover:text-white disabled:cursor-not-allowed disabled:border-grey-medium disabled:bg-grey-bg disabled:text-grey-medium data-[state=checked]:bg-black data-[state=checked]:text-white">
        {children}
        <svg width="1" height="1" xmlns="http://www.w3.org/2000/svg" className="absolute inset-0 hidden h-full w-full fill-current text-grey-medium group-disabled:block">
            <g>
                <line stroke="currentColor" y2="100%" x2="100%" y1="0" x1="0"/>
            </g>
        </svg>
    </RadioGroupPrimitive.Item>);
const Title = ({ children }) => <div className="text-sm font-medium text-black">{children}</div>;
const ChooseSize = ({ variants, setSku }) => {
    const [size, setSize] = useState(null);
    const { $t } = useI18n();
    useEffect(() => {
        const item = variants.find((i) => i.size === size);
        if (item) {
            setSku(item.sku);
        }
    }, [setSku, size, variants]);
    useEffect(() => {
        const onStock = variants.filter((i) => i.isOnStock);
        const onStockSizes = [...new Set(onStock.map((i) => i.size))];
        if (onStockSizes.length === 1) {
            setSize(onStockSizes.at(0));
        }
    }, [variants]);
    return (<div>
            <Title>{$t("Choose a size")}</Title>
            <RadioRoot value={size} onValueChange={(size) => setSize(size)}>
                {variants.map((i) => (<RadioItem key={i.size} value={i.size} disabled={!i.isOnStock}>
                        {i.size}
                    </RadioItem>))}
            </RadioRoot>
        </div>);
};
const ChooseWaistLength = ({ variants, setSku, lengths, waists, }) => {
    const { $t } = useI18n();
    const [waist, setWaist] = useState(null);
    const [length, setLength] = useState(null);
    useEffect(() => {
        const item = variants.find((i) => i.length === length && i.waist === waist);
        if (item) {
            setSku(item.sku);
        }
    }, [length, setSku, variants, waist]);
    useEffect(() => {
        const onStock = variants.filter((i) => i.isOnStock);
        const onStockWaists = [...new Set(onStock.map((i) => i.waist))];
        if (onStockWaists.length === 1) {
            setWaist(onStockWaists[0]);
        }
    }, [variants]);
    useEffect(() => {
        const onStock = variants.filter((i) => i.isOnStock);
        const onStockLengths = [...new Set(onStock.map((i) => i.length))];
        if (onStockLengths.length === 1) {
            setLength(onStockLengths[0]);
        }
    }, [variants]);
    return (<>
            <div>
                <Title>{$t("Choose waist size")}</Title>
                <RadioRoot value={waist} onValueChange={(waist) => setWaist(waist)}>
                    {waists.map((waist) => {
            let disabled = false;
            if (length) {
                const item = variants.find((i) => i.length === length && waist === i.waist);
                if (!(item === null || item === void 0 ? void 0 : item.isOnStock)) {
                    disabled = true;
                }
            }
            else {
                disabled = variants.filter((i) => i.waist === waist).every((i) => !i.isOnStock);
            }
            return (<RadioItem key={waist} value={waist} disabled={disabled}>
                                {waist}
                            </RadioItem>);
        })}
                </RadioRoot>
            </div>

            <div className="mt-3">
                <Title>{$t("Choose length size")}</Title>
                <RadioRoot value={length} onValueChange={(length) => setLength(length)}>
                    {lengths.map((length) => {
            let disabled = false;
            if (waist) {
                const item = variants.find((i) => i.length === length && waist === i.waist);
                if (!(item === null || item === void 0 ? void 0 : item.isOnStock)) {
                    disabled = true;
                }
            }
            else {
                disabled = variants.filter((i) => i.length === length).every((i) => !i.isOnStock);
            }
            return (<RadioItem key={length} value={length} disabled={disabled}>
                                {length}
                            </RadioItem>);
        })}
                </RadioRoot>
            </div>
        </>);
};
export const Sizes = ({ sizes, setSku }) => (<div id="sizeSelector">
        {sizes.type === "size" ? (<ChooseSize variants={sizes.sizes} setSku={setSku}/>) : (<ChooseWaistLength setSku={setSku} lengths={sizes.lengths} variants={sizes.sizes} waists={sizes.waists}/>)}
    </div>);
