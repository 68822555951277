import { localStorage } from "@jog/shared";
import { useSession } from "next-auth/react";
import { useEffect, useMemo, useState } from "react";
import { StatsigProvider as StatsigProviderBase, useGate as useGateBase } from "statsig-react";
import { v4 } from "uuid";
export const useGate = (gateName, ignoreOverrides) => {
    const data = useGateBase(gateName, ignoreOverrides);
    const [value, setValue] = useState(false);
    useEffect(() => {
        setValue((v) => v || data.value);
    }, [data]);
    return useMemo(() => ({ value, isLoading: data.isLoading }), [value, data.isLoading]);
};
const StatsigState = () => {
    return <></>;
};
export const StatsigProvider = ({ children }) => {
    const key = process.env.NEXT_PUBLIC_STATSIG_CLIENT_API_KEY || "";
    const session = useSession();
    const user = useMemo(() => {
        var _a, _b;
        if (session.status === "loading")
            return {};
        const userID = localStorage.getItem("statsigUserId") || v4();
        const email = ((_b = (_a = session === null || session === void 0 ? void 0 : session.data) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.email) || "";
        const preview = process.env.NEXT_PUBLIC_STATSIG_PREVIEW || "";
        return email ? { email, userID, custom: { preview } } : { userID, custom: { preview } };
    }, [session]);
    useEffect(() => {
        if (user.userID)
            localStorage.setItem("statsigUserId", user.userID);
    }, [user]);
    if (!key)
        return <>{children}</>;
    return (<StatsigProviderBase sdkKey={key} user={user}>
            <StatsigState />
            {children}
        </StatsigProviderBase>);
};
