import { addToWishlist, getSimpleWishlist, getWishlist, localStorage, mergeWishlist, myWishlist, removeFromWishlist, transGtmId, } from "@jog/shared";
import { create } from "zustand";
export const initWishlistStore = () => create((set, get) => ({
    pending: false,
    actionPending: false,
    wishlistId: null,
    wishlist: null,
    simpleWishlist: null,
    error: null,
    clearError: () => set({ error: null }),
    actionOfSimpleWishlist: async (fn, isAction = false) => {
        if (isAction) {
            set({ actionPending: true, error: null });
        }
        else {
            set({ pending: true, error: null });
        }
        await fn()
            .then((simpleWishlist) => {
            localStorage.setItem("wishlistId", simpleWishlist.id);
            set({ wishlistId: simpleWishlist.id, simpleWishlist, pending: false, actionPending: false });
        })
            .catch((e) => {
            set({ pending: false, error: e.error, actionPending: false });
        });
    },
    actionOfWishlist: async (fn) => {
        set({ pending: true, error: null });
        await fn()
            .then((wishlist) => {
            localStorage.setItem("wishlistId", wishlist.id);
            const processedLineItems = wishlist.lineItems.map((item) => {
                return {
                    ...item,
                    discountedPrice: Math.round((item.discountedPrice || 0) * 100),
                    originalPrice: Math.round((item.originalPrice || 0) * 100),
                };
            });
            set({
                wishlistId: wishlist.id,
                wishlist: {
                    ...wishlist,
                    lineItems: processedLineItems,
                },
                pending: false,
            });
        })
            .catch((e) => {
            set({ pending: false, error: e.error });
        });
    },
    initWishlist: async () => {
        const wishlistId = localStorage.getItem("wishlistId");
        if (!wishlistId) {
            return;
        }
        await get().actionOfSimpleWishlist(() => getSimpleWishlist(wishlistId).then((res) => res.body));
    },
    addWishlistItem: async (productId) => {
        var _a;
        const id = ((_a = get()) === null || _a === void 0 ? void 0 : _a.wishlistId) || "";
        await get().actionOfSimpleWishlist(() => addToWishlist(id, productId).then((res) => res.body), true);
    },
    removeWishlistItem: async (lineItemId) => {
        var _a;
        const id = ((_a = get()) === null || _a === void 0 ? void 0 : _a.wishlistId) || "";
        if (!id)
            return;
        await get().actionOfSimpleWishlist(() => removeFromWishlist(id, lineItemId)
            .then((res) => res.body)
            .then((data) => {
            var _a;
            const newWishlist = ((_a = get().wishlist) === null || _a === void 0 ? void 0 : _a.lineItems.filter((item) => item.id !== lineItemId)) || [];
            set({ wishlist: { id, lineItems: newWishlist } });
            return data;
        }), true);
    },
    mergeWishlist: async () => {
        const wishlistId = localStorage.getItem("wishlistId");
        if (!wishlistId)
            return;
        await get().actionOfSimpleWishlist(() => mergeWishlist(wishlistId).then((res) => res.body));
    },
    getWishlist: async () => {
        var _a;
        const wishlistId = ((_a = get()) === null || _a === void 0 ? void 0 : _a.wishlistId) || localStorage.getItem("wishlistId") || "";
        if (!wishlistId)
            return;
        await get().actionOfWishlist(() => getWishlist(wishlistId)
            .then((res) => res.body)
            .then((wishlist) => {
            const lineItems = wishlist.lineItems.map((lineItem) => {
                var _a, _b, _c, _d;
                const articleName = ((_b = (_a = lineItem.attributes) === null || _a === void 0 ? void 0 : _a.find((attribute) => attribute.name === "articlename")) === null || _b === void 0 ? void 0 : _b.value) || "";
                const colorCode = ((_d = (_c = lineItem.attributes) === null || _c === void 0 ? void 0 : _c.find((attribute) => attribute.name === "colorcode")) === null || _d === void 0 ? void 0 : _d.value) || "";
                return {
                    ...lineItem,
                    gtmId: transGtmId({ articleName, colorCode }),
                };
            });
            return {
                ...wishlist,
                lineItems,
            };
        }));
    },
    myWishlist: async () => {
        await get().actionOfSimpleWishlist(() => myWishlist().then((res) => res.body));
    },
}));
