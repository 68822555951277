import { sleep } from "@jog/shared";
import { createContext, useCallback, useContext, useRef, useState, } from "react";
const LastListerContext = createContext({
    path: "",
    setPath: () => { },
    recordLister: () => { },
    checkScroll: () => { },
});
export const LastListerProvider = ({ children }) => {
    const [path, setPath] = useState("");
    const state = useRef(null);
    const recordLister = ({ pageurl }, { itemno }) => {
        state.current = { pageurl, itemno };
    };
    const checkScroll = useCallback(async (properties, items) => {
        if (!state.current)
            return;
        const item = items.find(({ itemno }) => itemno === state.current.itemno);
        if (item && properties.pageurl === state.current.pageurl) {
            await sleep(500);
            const element = document.querySelector(`#item_${item.itemno}`);
            if (element)
                element.scrollIntoView({ behavior: "smooth", block: "center" });
        }
        state.current = null;
    }, []);
    return (<LastListerContext.Provider value={{ path, setPath, recordLister, checkScroll }}>
            {children}
        </LastListerContext.Provider>);
};
export const useLastLister = () => useContext(LastListerContext);
