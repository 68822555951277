import { useSimplePdp } from "@/lib/preFetch/preFetchPdp";
import { usePromotionTag } from "@/lib/preFetch/prefetchPromoTag";
import prismicColors from "@/lib/prismicio/prismic-colors.json";
import { Link, ListerTileImage, PromotionalImage, getColor, getProductUrl, transMoney, useGallery, useI18n, useTile, } from "@jog/react-components";
import { colorLanguageConverter, getGtmIdFromTW } from "@jog/shared";
import classNames from "classnames";
import { useRouter } from "next/router";
import { Fragment } from "react";
import { Favorite } from "../Favorite";
const Discount = ({ product: { isDiscount, firstPrice, lowestPrice, price }, }) => {
    const { $t } = useI18n();
    if (!isDiscount)
        return <></>;
    return (<div className="mt-1 text-[10px] text-grey-dark">
            <p>{$t(`Original price: {price}`, { price: transMoney(+firstPrice * 100, false) })}</p>
            {price === lowestPrice || lowestPrice === +firstPrice ? null : (<p>{$t(`Lowest price last 30 days: {price}`, { price: transMoney(lowestPrice * 100, false) })}</p>)}
        </div>);
};
const Price = ({ product: { isDiscount, price } }) => (<div className={classNames(isDiscount ? "text-red-main" : "text-black", "mt-1 font-primary text-[13px] font-medium leading-4")}>
        {transMoney(price * 100)}
    </div>);
const Group = ({ product: { name, linkItem }, data, onClick, tile: { group, hasGroup, setActiveProduct }, }) => {
    const router = useRouter();
    if (!hasGroup)
        return <></>;
    return (<div className="-mr-1 mt-1 flex h-5 max-w-2/3 items-center lg:-mr-1.5">
            {group.slice(0, 4).map((i) => {
            const color = getColor(i);
            const circle = (<span title={color || name} className="inline-block h-4 w-4 cursor-pointer rounded-full border" style={{
                    backgroundColor: colorLanguageConverter(color, prismicColors),
                }}/>);
            const commonClass = `lg:mr-1.5 mr-1 items-center justify-center`;
            return (<Fragment key={`${data.itemno}_${i.itemno}`}>
                        {/*mobile*/}
                        <span title={name} className={classNames(commonClass, "flex lg:hidden")} onClick={(e) => {
                    e.preventDefault();
                    onClick === null || onClick === void 0 ? void 0 : onClick(data);
                    router.push(linkItem);
                }}>
                            {circle}
                        </span>
                        {/*desktop*/}
                        <span title={i.title} className={classNames(commonClass, "hidden lg:flex")} onMouseEnter={() => setActiveProduct(i)} onMouseLeave={() => setActiveProduct(data)} onClick={(e) => {
                    e.preventDefault();
                    onClick === null || onClick === void 0 ? void 0 : onClick(i);
                    router.push(getProductUrl(i));
                }}>
                            {circle}
                        </span>
                    </Fragment>);
        })}
            {group.length > 4 && <div className="mr-1.5 flex text-xs">{`+${group.length - 4}`}</div>}
        </div>);
};
export const ListerTile = ({ data, className, group: _group, onClick }) => {
    const { product, promotion, ...tile } = useTile(data, _group);
    const promotionTag = usePromotionTag(promotion);
    const { mobileSortingByName, desktopSortingByName } = useSimplePdp();
    const gallery = useGallery(tile.activeProduct, mobileSortingByName, desktopSortingByName);
    // id={`item_${data.itemno}`} is for scroll to item, check useLastLister.tsx
    return (<div className={classNames(className, "relative")} id={`item_${data.itemno}`}>
            <Link href={product.linkItem} title={product.name} onClick={() => onClick(data)}>
                <div className="relative flex h-full flex-col bg-white">
                    <div className="relative">
                        <ListerTileImage name={product.name} gallery={gallery}/>
                        <PromotionalImage className="absolute top-2.5 right-0 w-17.5 object-cover" promotionTag={promotionTag}/>
                        <Favorite productId={data.itemno} name={product.name} price={product.price} gtmId={getGtmIdFromTW(data)}/>
                    </div>
                    <div className="font-base line-clamp-2 mt-2 p-0 font-primary text-xs font-medium capitalize text-black lg:line-clamp-none">
                        {product.name}
                    </div>
                    <div className="mt-1 flex items-center justify-between">
                        <Price product={product}/>
                        <Group tile={tile} onClick={onClick} data={data} product={product}/>
                    </div>
                    <Discount product={product}/>
                </div>
            </Link>
        </div>);
};
