import { CTABase } from "@jog/react-components";
import classNames from "classnames";
export const Button = ({ bgColor, fontSize, color, paddingMobile, disabled, children, style, className, ...props }) => {
    return (<button className={classNames("inline-block border-none bg-black font-primary text-xs uppercase text-white hover:underline focus:outline-none lg:py-2.5 lg:px-12.5", disabled ? "pointer-events-none opacity-60" : "pointer-events-auto cursor-pointer opacity-100", paddingMobile || "py-2.5 px-12.5", className)} {...props} style={{ ...style, backgroundColor: bgColor, fontSize, color }}>
            {children}
        </button>);
};
export const CTA = ({ children, className, ...props }) => (<CTABase className={classNames("py-3 px-4 text-sm font-normal leading-tight", className)} {...props}>
        {children}
    </CTABase>);
