import { getGtmIdFromTW } from "../helper";
const init = () => (window.dataLayer = window.dataLayer || []);
export const gtmPush = (data) => {
    init();
    window.dataLayer.push(data);
};
export const gtmHomepage = () => {
    gtmPush({ event: "homepage" });
};
export const gtmEmail = (email) => {
    gtmPush({ email });
};
export const adpageHook = (api, body) => {
    const base = (process.env.NEXT_PUBLIC_ADPAGE_TAGGING || "").replace(/\/$/, "");
    if (!base)
        return;
    return fetch(base + "/" + api.replace(/^\//, ""), {
        method: "POST",
        body: JSON.stringify(body),
    });
};
export const getImpressions = (items, getCategory) => items.map((product, index) => {
    const category = getCategory(product);
    return {
        id: getGtmIdFromTW(product),
        name: product.title,
        price: product.price,
        brand: product.brand,
        category,
        variant: "",
        list: category,
        position: index + 1,
    };
});
