import { gtmPush } from ".";
import { convertCentsToEuros, getRevenue, transGtmId } from "../helper";
import { checkoutTransactionEventSent, localStorage } from "../localStorage";
export const gtmTransactionSend = (order, affiliation) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    if (!order || !order.lineItems)
        return;
    const products = order.lineItems.map((product) => ({
        name: product.name.en,
        id: transGtmId({ articleName: product.articleName, colorCode: product.colorCode }),
        price: parseFloat(convertCentsToEuros(product.prices.totalPrice.centAmount / product.quantity)),
        brand: "",
        category: product.categoryAsUrl,
        variant: "",
        quantity: product.quantity,
    }));
    const { shipping, tax, revenue } = getRevenue(order);
    const data = {
        ecommerce: {
            purchase: {
                actionField: {
                    id: order.orderNumber,
                    affiliation,
                    revenue: (revenue + shipping).toFixed(2),
                    tax,
                    shipping,
                    coupon: (order.vouchers.length > 0 && ((_a = order.vouchers[0]) === null || _a === void 0 ? void 0 : _a.code)) || "",
                    metric1: order.vouchers.length > 0 ? convertCentsToEuros((_b = order.vouchers[0]) === null || _b === void 0 ? void 0 : _b.centAmount) : "",
                    email: (_c = order.billingAddress) === null || _c === void 0 ? void 0 : _c.email,
                    firstName: (_d = order.billingAddress) === null || _d === void 0 ? void 0 : _d.firstName,
                    lastName: (_e = order.billingAddress) === null || _e === void 0 ? void 0 : _e.lastName,
                    paymentMethod: (_k = (_j = (_h = (_g = (_f = order.paymentInfo) === null || _f === void 0 ? void 0 : _f.payments) === null || _g === void 0 ? void 0 : _g[0]) === null || _h === void 0 ? void 0 : _h.obj) === null || _j === void 0 ? void 0 : _j.paymentMethodInfo) === null || _k === void 0 ? void 0 : _k.method,
                },
                products,
            },
        },
        hoeveelsteorder: 1,
    };
    const flag = checkoutTransactionEventSent(order.orderNumber);
    if (flag.exist()) {
        gtmPush({ ...data, event: "transaction" });
    }
    else {
        gtmPush({ ...data, event: "transaction-send" });
        flag.mark();
    }
};
export const gtmTrustedShops = (order) => {
    var _a, _b, _c, _d, _e, _f, _g;
    if (!order || !order.lineItems)
        return;
    const cartProductExtras = localStorage.getItem("cartProductExtras") || {};
    const lineItems = order.lineItems || [];
    const tsCheckoutProducts = lineItems.map((item) => {
        var _a, _b, _c, _d;
        return ({
            tsCheckoutProductUrl: (_b = (_a = cartProductExtras[item.productId]) === null || _a === void 0 ? void 0 : _a.url) !== null && _b !== void 0 ? _b : "",
            tsCheckoutProductImageUrl: item.image,
            tsCheckoutProductName: item.name.en,
            tsCheckoutProductSKU: item.sku,
            tsCheckoutProductGTIN: item.sku,
            tsCheckoutProductBrand: (_d = (_c = cartProductExtras[item.productId]) === null || _c === void 0 ? void 0 : _c.brand) !== null && _d !== void 0 ? _d : "",
        });
    });
    gtmPush({
        event: "TrustedShops",
        ts_checkout: {
            tsCheckoutOrderNr: order === null || order === void 0 ? void 0 : order.orderNumber,
            tsCheckoutBuyerEmail: (_a = order === null || order === void 0 ? void 0 : order.billingAddress) === null || _a === void 0 ? void 0 : _a.email,
            tsCheckoutOrderAmount: convertCentsToEuros((_b = order === null || order === void 0 ? void 0 : order.total) === null || _b === void 0 ? void 0 : _b.centAmount),
            tsCheckoutOrderCurrency: "EUR",
            tsCheckoutOrderPaymentType: (_g = (_f = (_e = (_d = (_c = order === null || order === void 0 ? void 0 : order.paymentInfo) === null || _c === void 0 ? void 0 : _c.payments) === null || _d === void 0 ? void 0 : _d[0]) === null || _e === void 0 ? void 0 : _e.obj) === null || _f === void 0 ? void 0 : _f.paymentMethodInfo) === null || _g === void 0 ? void 0 : _g.method,
            tsCheckoutProducts,
        },
    });
};
