import { genPrismicJson } from "./log";
import { prebuildEmailTemplate } from "./prebuildEmailTemplate";
import { prebuildRedirect } from "./prebuildRedirect";
import { prebuildRobotsTxt } from "./prebuildRobotsTxt";
export const prebuild = async (fetch) => {
    await prebuildRedirect(fetch.redirect);
    await genPrismicJson("campaign", fetch.campaign);
    await genPrismicJson("colors", fetch.colors);
    await genPrismicJson("text-conversion", fetch.textConversion);
    await genPrismicJson("error-page", fetch.errorPage);
    await prebuildRobotsTxt(fetch.robotsTxt);
    await genPrismicJson("email-validation", fetch.emailValidation);
    await genPrismicJson("facets-to-index", fetch.facetsToIndex);
    await genPrismicJson("social", fetch.social);
    await genPrismicJson("meta", fetch.meta);
    if (fetch.storelocator)
        await genPrismicJson("storelocator", fetch.storelocator);
    await genPrismicJson("layout", fetch.layout);
    await genPrismicJson("not-found", fetch.notFound);
    await genPrismicJson("server-error-page", fetch.serverError);
    await prebuildEmailTemplate(fetch.emailTemplate);
};
