export const jogStores = [
    { store: 101, name: "MEPPEL", field: "custom_store_id_101_meppel" },
    { store: 102, name: "OSS", field: "custom_store_id_102_oss" },
    { store: 103, name: "EINDHOVEN (D)", field: "custom_store_id_103_eindhoven" },
    { store: 104, name: "ARNHEM", field: "custom_store_id_104_arnhem" },
    { store: 105, name: "DEN BOSCH", field: "custom_store_id_105_denbosch" },
    { store: 106, name: "OOSTERHOUT", field: "custom_store_id_106_oosterhout" },
    { store: 107, name: "TILBURG", field: "custom_store_id_107_tilburg" },
    { store: 108, name: "WAALWIJK", field: "custom_store_id_108_waalwijk" },
    { store: 110, name: "ELBURG", field: "custom_store_id_110_elburg" },
    { store: 111, name: "WINSCHOTEN", field: "custom_store_id_111_winschoten" },
    { store: 112, name: "TIEL", field: "custom_store_id_112_tiel" },
    { store: 113, name: "BERGEN OP ZOOM", field: "custom_store_id_113_bergenopzoom" },
    { store: 116, name: "ROERMOND", field: "custom_store_id_116_roermond" },
    { store: 118, name: "VELDHOVEN", field: "custom_store_id_118_veldhoven" },
    { store: 120, name: "PUTTEN", field: "custom_store_id_120_putten" },
    { store: 121, name: "UDEN", field: "custom_store_id_121_uden" },
    { store: 122, name: "HEERLEN", field: "custom_store_id_122_heerlen" },
    { store: 123, name: "SITTARD", field: "custom_store_id_123_sittard" },
    { store: 124, name: "MAASTRICHT", field: "custom_store_id_124_maastricht" },
    { store: 125, name: "BREDA", field: "custom_store_id_125_breda" },
    { store: 127, name: "ETTEN-LEUR", field: "custom_store_id_127_ettenleur" },
    { store: 128, name: "ROOSENDAAL", field: "custom_store_id_128_roosendaal" },
    { store: 129, name: "VENRAY", field: "custom_store_id_129_venray" },
    { store: 130, name: "LEIDEN", field: "custom_store_id_130_leiden" },
    { store: 131, name: "BRUNSSUM", field: "custom_store_id_131_brunssum" },
    { store: 134, name: "DELFT", field: "custom_store_id_134_delft" },
    { store: 135, name: "LEIDSCHENDAM", field: "custom_store_id_135_leidschendam" },
    { store: 137, name: "MIDDELBURG", field: "custom_store_id_137_middelburg" },
    { store: 140, name: "LEEUWARDEN", field: "custom_store_id_140_leeuwarden" },
    { store: 141, name: "HEERENVEEN", field: "custom_store_id_141_heerenveen" },
    { store: 142, name: "SNEEK", field: "custom_store_id_142_sneek" },
    { store: 143, name: "DRACHTEN", field: "custom_store_id_143_drachten" },
    { store: 144, name: "GRONINGEN", field: "custom_store_id_144_groningen" },
    { store: 145, name: "FRANEKER", field: "custom_store_id_145_franeker" },
    { store: 146, name: "KAMPEN", field: "custom_store_id_146_kampen" },
    { store: 147, name: "EMMELOORD", field: "custom_store_id_147_emmeloord" },
    { store: 149, name: "GRONINGEN", field: "custom_store_id_149_groningen" },
    { store: 151, name: "ZWOLLE", field: "custom_store_id_151_zwolle" },
    { store: 153, name: "WAGENINGEN", field: "custom_store_id_153_wageningen" },
    { store: 154, name: "DOETINCHEM", field: "custom_store_id_154_doetinchem" },
    { store: 157, name: "ZEVENAAR", field: "custom_store_id_157_zevenaar" },
    { store: 159, name: "HENGELO", field: "custom_store_id_159_hengelo" },
    { store: 160, name: "HOOFDDORP", field: "custom_store_id_160_hoofddorp" },
    { store: 162, name: "HARDERWIJK", field: "custom_store_id_162_harderwijk" },
    { store: 163, name: "ENSCHEDE", field: "custom_store_id_163_enschede" },
    { store: 167, name: "EDE", field: "custom_store_id_167_ede" },
    { store: 169, name: "ALMELO", field: "custom_store_id_169_almelo" },
    { store: 174, name: "NIEUWEGEIN", field: "custom_store_id_174_nieuwegein" },
    { store: 175, name: "NIJMEGEN", field: "custom_store_id_175_nijmegen" },
    { store: 176, name: "NIJMEGEN", field: "custom_store_id_176_nijmegen" },
    { store: 177, name: "ARNHEM", field: "custom_store_id_177_arnhem" },
    { store: 179, name: "AMERSFOORT", field: "custom_store_id_179_amersfoort" },
    { store: 180, name: "HARDENBERG", field: "custom_store_id_180_hardenberg" },
    { store: 181, name: "GROOTEBROEK", field: "custom_store_id_181_grootebroek" },
    { store: 184, name: "ROTTERDAM", field: "custom_store_id_184_rotterdam" },
    { store: 185, name: "DEN BOSCH", field: "custom_store_id_185_den_bosch" },
    { store: 186, name: "ZAANDAM", field: "custom_store_id_186_zaandam" },
    { store: 187, name: "ZOETERMEER", field: "custom_store_id_187_zoetermeer" },
    { store: 188, name: "ALKMAAR", field: "custom_store_id_188_alkmaar" },
    { store: 193, name: "GOIRLE", field: "custom_store_id_193_goirle" },
    { store: 197, name: "NOORDWIJK", field: "custom_store_id_197_noordwijk" },
    { store: 199, name: "EINDHOVEN ( W)", field: "custom_store_id_199_eindhoven" },
    { store: 201, name: "SCHIJNDEL", field: "custom_store_id_201_schijndel" },
    { store: 203, name: "WEERT", field: "custom_store_id_203_weert" },
    { store: 204, name: "WINTERSWIJK", field: "custom_store_id_204_winterswijk" },
    { store: 207, name: "VENLO", field: "custom_store_id_207_venlo" },
    { store: 208, name: "EMMEN", field: "custom_store_id_208_emmen" },
    { store: 209, name: "ALMERE", field: "custom_store_id_209_almere" },
    { store: 210, name: "HELLEVOETSLUIS", field: "custom_store_id_210_hellevoetsluis" },
    { store: 212, name: "VEENENDAAL", field: "custom_store_id_212_veenendaal" },
    { store: 213, name: "UTRECHT", field: "custom_store_id_213_utrecht" },
    { store: 215, name: "DEVENTER", field: "custom_store_id_215_deventer" },
    { store: 216, name: "NAALDWIJK", field: "custom_store_id_216_naaldwijk" },
    { store: 217, name: "HELMOND", field: "custom_store_id_217_helmond" },
    { store: 218, name: "ZUTPHEN", field: "custom_store_id_218_zutphen" },
    { store: 219, name: "HEERHUGOWAARD", field: "custom_store_id_219_heerhugowaard" },
    { store: 220, name: "CAPELLE", field: "custom_store_id_220_capelle" },
    { store: 221, name: "APELDOORN", field: "custom_store_id_221_apeldoorn" },
    { store: 222, name: "ASSEN", field: "custom_store_id_222_assen" },
    { store: 223, name: "BEEK", field: "custom_store_id_223_beek" },
    { store: 224, name: "SCHAGEN", field: "custom_store_id_224_schagen" },
    { store: 225, name: "HOORN", field: "custom_store_id_225_hoorn" },
    { store: 226, name: "GOES", field: "custom_store_id_226_goes" },
    { store: 227, name: "Alphen ad Rijn", field: "custom_store_id_227_alphenadrijn" },
    { store: 700, name: "JeansCentre.nl", field: "custom_store_id_700_webshop" },
];
